.img-size-compare {
    height: 270px;
    width: 270px;
    border-radius: 50%;
    object-position: 'center';
    margin: 30px;
    border: 2px dashed;
    padding: 15px;
}

.img-size-compare-skele {
    height: 270px;
    width: 270px;
    border-radius: 50%;
    object-position: 'center';
    margin: 30px;
    padding: 15px;
}

.p-text {
    font-size: 14px;
}


.custom-bg-0 {
    background: #F4F4F4;
}

.prod-name-0-color {
    color: #003366;
}

.p-text-0-color {
    color: #003366
}

/* .disc-0-color {
    color: black;
} */

.custom-img-0-border {
    border-color: #C8811A !important;
}


.custom-bg-1 {
    background: #CDDBCD;
}

.prod-name-1-color {
    color: #003366;
}

.p-text-1-color {
    color: #5CB85C
}

/* .disc-1-color {
    color: #fff;
} */

.custom-img-1-border {
    border-color: #fff !important;
}

.custom-bg-2 {
    background: #EEF6F9;
}

.prod-name-2-color {
    color: #003366;
}

.p-text-2-color {
    color: #487BAD
}

/* .disc-2-color {
    color: #585858;
} */

.custom-img-2-border {
    border-color: #3D3D6B !important;
}


.custom-bg-3 {
    background: #E4DACE;
}

.prod-name-3-color {
    color: #003366;
}

.p-text-3-color {
    color: #D7B58B
}

/* .disc-3-color {
    color: #fff;
} */

.custom-img-3-border {
    border-color: #fff !important;
}

.dot-0-color {
    height: 15 !important;
    width: 15 !important;
    border-radius: "100%" !important;
    background-color: 'red' !important;
    margin-right: 5 !important
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
    .media_box{
        overflow-x: auto;
    }
    .sm_screenbtn{
        margin-top: -30px !important;
    }
}
