/* .custom-button {
    background-color: #FF603D !important;
} */
.login-background{
    background: linear-gradient(98.13deg, #E8DED0 0%, #033C4E 143.86%); 
}

.iconbtn{
    position: absolute;
    top: 0;
    right: 0;
}