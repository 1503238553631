.lastcard {
    height: 750px !important;
}

.trendproduct-responsive {
    width: 100% !important;

}
.suggested-product-container{
    flex-direction: row !important;
}
.suggested-product-image{
    width: auto !important;
}
/* 
@media only screen and (max-width: 2560px) and (min-width: 2200px) {
    .trendproduct-responsive {
        width: 26% !important;
    }
}
@media only screen and (max-width: 2100px) and (min-width: 1888px) {
    .trendproduct-responsive {
        width: 31% !important;
    }
}
@media only screen and (max-width: 1888px) and (min-width: 1550px) {
    .trendproduct-responsive {
        width: 42% !important;
    }
}
@media only screen and (max-width: 1550px) and (min-width: 1440px) {
    .trendproduct-responsive {
        width: 45% !important;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1300px) {
    .trendproduct-responsive {
        width: 48% !important;
    }
}
@media only screen and (max-width: 1300px) and (min-width: 1261px) {
    .trendproduct-responsive {
        width: 50% !important;
    }
} */
@media only screen and (max-width: 1261px) {
    .suggested-product-container{
        flex-direction: column !important;
    }
    .trendproduct-responsive {
        width: 100% !important;
    }
    .suggested-product-image{
        width: 100% !important;
    }
    
}
@media only screen and (max-width: 769px) {
    .lastcard {
        height: 328px !important;
    }
}