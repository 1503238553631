.bottom-fix{
    /* position: absolute, i !important; */
left: 0 !important;
bottom: 0 !important;
right: 0 !important;
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
    .sm_scr{
        display: flex !important;
        justify-content: center !important;
    }
    .sm_scr_p{
        display: flex !important;
        justify-content: center !important;
    }
    .sm_scr_logo{
        margin-bottom: 10px !important;
    }
}