

.dropdown-item:hover {
    color: black !important;
    background-color: #D3EBD1 !important;
}
.dropdown-item a:hover {
    color: "#fff" !important;
    background-color: #034053 !important;
}
.logo-width{
    width:'auto'
}
.nav-bg {
    background: #fff;
    /* background: linear-gradient(180deg, #F7EFE8 0%, #FDF9F6 76.33%); */
    box-shadow: 0px 3px 25px #F7EFE8;
    /* transition: " 0.5s all"  */
    transition: " 0.5s ease-in" ;

    /* transition:" transform .2s cubic-bezier(.55,.06,.68,.19),background-color .5s";
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s; */
}

.nav-bg2 {
    background-color: transparent;
    transition: " 0.5s ease-in" ;
    /* transition: "transform .2s cubic-bezier(.55,.06,.68,.19),background-color .5s";
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s; */
}

.dropdown-divider {
    margin: 0 !important;
}

.signin-button {
    background-color: #5CB85C !important;
    border-color: #5CB85C !important;

}

.profile {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid grey;
    margin-right: 10px;
}

#collasible-nav-dropdown {
    color: #000 !important
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
}

.profile-menu {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid grey;
    margin-right: 10px;
}
@media only screen and (max-width: 768px){
    /* .navbar-collapse{
        background: linear-gradient(45deg, #d1c363, #0f514e);
    } */
    .nav-bg2 {
        /* background: #000; */
        background: linear-gradient(180deg, #F7EFE8 0%, #FDF9F6 76.33%);
        box-shadow: 0px 3px 25px #F7EFE8;
        /* transition: " 0.5s all"  */
        transition:" transform .2s cubic-bezier(.55,.06,.68,.19),background-color .5s"
    }
}