.contact-bg {
  background: radial-gradient(
      86.59% 1024.28% at 46.67% 44.66%,
      #95a8ad 0%,
      #033c4e 91.15%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

@media only screen and (max-width: 768px) {
  .contact-bg {
    background: white;
  }

  .centered_p {
    font-size: 10px !important;
  }

  .centered {
    font-size: 15px !important;
  }
  .sm_contact {
    width: auto !important;
  }
}

.bg_img {
  position: relative;
  text-align: center;
  color: white;
}
/* .bg_img_about{
    background-image: url('../../assets/images/bg_about.png');
    background-size: contain;
    background-repeat: repeat-x;
    height: 50vh;
}
.centered_new{
    font-size: 40px;
    font-weight: 700;
} */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 700;
}

.centered_p {
  font-size: 21px;
  font-weight: 400;
}

.box_card {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.bg_aboutcard {
  background-image: url("../../assets/images/about_bg_img.png");
  background-size: cover;
  background-repeat: repeat-x;
}
/* ----------------------- */
.con_rel {
  position: relative;
  width: auto;
}
.img_shad {
  opacity: 0.3;
  display: block;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}
.middle_text {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.tex_div {
  background-color: #033c4e;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 10px;
}
/* -------------------- */
.aboutcards_img {
  position: absolute;
  margin: 14px;
}

.img_position_r {
  position: relative;
  width: 100%;
  height: 100%;
}

.img_position {
  position: absolute;
  top: -50px;
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  .aboutcards_img,
  h4,
  p {
    font-size: medium !important;
  }
  .centered {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .centered_p {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .sm_contact {
    width: auto !important;
  }
}
