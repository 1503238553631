/* .div-border-custom {
    border: 2px dashed #FF603D;
    border-radius: 50%;
} */
.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.product-background{
    background-image: url('../../assets/images/product_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.img-bg{
    background-color: #fff;
    border-radius:50%;
    padding: 20px;
    box-shadow:  0px 0px 20px 0px #1D161712;
;
    
}
.img-size {
    height: 300px;
    width: 300px;
    /* border-radius: 50%; */
    object-position: 'center';
    margin: 30px;

   /* border: 2px dashed #C8811A; */
   padding: 20px;
   aspect-ratio: "9/16";
}

.dual-color-card {
    width: "150px";
    height: "47px";
    background-color: '#C8811A';
    border-top-left-radius: "8px";
    border-bottom-left-radius: '8px';

}

@media only screen and (max-width:573px) {
    .img-size {
        height: 150px !important;
        width: 150px !important;
        
        object-position: 'center';
        margin: 10px
    }

    .div-border-custom {
        border: 2px dashed #C8811A;
        border-radius: 80%;
    }
}

.hover_bg:hover{
    background-color: #5CB85C;
    color: white;
}
.hover_bg_tx{
    color: #212529;
}
.hover_bg:hover .hover_bg_tx{
    color: white;
}