.scrolltable {
  overflow-y: scroll;
  height: 150px;
}

/* .background-popular {
  background-image: url('../../assets/images/popularbackground.png');
  background-size: cover;
  background-repeat: repeat-x;
}

.background-trend {
  background-image: url('../../assets/images/trendbackground.png');
  background-size: cover;
  background-repeat: repeat-x;
} */

.background-card {
  background-image: url('../../assets/images/cardbackground.png');
  background-size: contain;
  background-repeat: repeat-x;
}

.card-custom {
  border-radius: 0.6em;
  overflow: hidden;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 400ms;
  margin-bottom: auto;
}

.card-custom:hover {
  transform: scale(1.04);
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* 
.card-body:hover .card-text {
  display: none
} */

.card-body:hover .card-button {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.card-button {
  display: none;
}

.card-text {
  display: block;
}


.button-view {
  border-radius: 2rem !important;

}

.signin-button {
  background-color: #C8811A !important;
  border-color: #C8811A !important;
}

.checkbox-custom {

  height: 18px !important;
  width: 18px !important;
  cursor: pointer !important
}

.compare-card {
  width: 11vw;
  height: 160px;
}
/* .accordion-button{
  background-color: #C3ADA8 !important;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-danger-color) !important;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-danger-color) !important;
  background-color: var(--bs-accordion-active-bg);
  
} */
.card-hover:hover {
  box-shadow: 0 4px 8px rgb(185 207 201); /* Add any box shadow effect you want */
  /* Add any other styles you want to apply on hover */
}

@media only screen and (max-width: 991px) {
  .compare-card {
    width: 21vw;
    height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-card {
    width: 48vw;
    height: 150px;
  }
}

@media only screen and (max-width: 533px) {
  .compare-card {
    width: 40vw;
    height: 150px;
  }
}

/* @media only screen and (max-width: 1218px) {
 .compare-card{
  width: 165px;
  height: 150px;
}

}
@media only screen and (max-width: 1024px) {
 .compare-card{
  width: 135px;
  height: 140px;
}

}
@media only screen and (max-width: 768px) {
 .compare-card{
  width: 170px;
  height: 140px;
}

}
@media only screen and (max-width: 425px) {
 .compare-card{
  width: 155px;
  height: 140px;
}

}
@media only screen and (max-width: 320px) {
 .compare-card{
  width: 134px;
  height: 140px;
}

} */