img-holder {
    margin: auto;
    width: 100px;
    height: 100px;
    margin-top: 1rem;
}

.img {
    width: 100px;
    height: 100px;
    object-fit: cover;

}

#input[type="file"] {
    display: none;
}

.label {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.image-upload {
    margin: auto;
    width: 200px;
    height: 50px;
    color: #ff9b44;
    border-radius: 10px;

    text-align: center;
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}