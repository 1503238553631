.background-img {
        background-image: url('../../assets/images/metabackgroud1.png');
        background-size: contain;
        background-repeat: repeat-x;
}

/* .content-bg{
        background: linear-gradient(45deg, #f443364f, #f443360f);
        width: 98vw;

} */
.carousel-bg {
        background: linear-gradient(258.6deg, #6F94A0 0.78%, #C09F6D 100%);
}

.banner-logo {
        width: auto;
}

.heading-font {
        font-size: 50px;
        font-weight: bold;
        text-align: center;
        color: #003366;
}

.heading-font-p {
        font-size: 20px;
        text-align: center;
        font-weight: 400;
        color: '#000'
}

.content-heading {
        font-size: 20px;
        font-weight: 500;
}

.content-p {
        font-size: 16px;
        color: '#434343';
}

.search-button {
        border-radius: 0px 10px 10px 0px;
        background-color: #5CB85C;
        border: 1px;
        height: 45px;
        color: white;
        font-weight: 500;
        /* margin-left: 10px; */
        width: 100%;
}

.search-inputs {
        border-radius: 10px 0px 0px 10px !important;
        height: 45px !important;
        padding-left: 20px !important;
}


.reset-button {
        border-radius: 30px;
        /* background-color: grey; */
        border: none;
        height: 45px;
        color: white;
        font-weight: 500;
        margin-left: -40px;
        width: 50%;
}

.margin-content {
        margin-top: 196px;
}

.box_card {
        margin-left: 12px !important;
        margin-right: 12px !important;
        background-image: url('../../assets/images/card_bg.png');
        background-color: #DEEBF0;
}

.box_card_GST {
        margin-left: 12px !important;
        margin-right: 12px !important;
}

.bg_homecard {
        background-image: url('../../assets/images/bg_home1.png');
        background-size: cover;
        background-repeat: repeat-x;
        margin-left: 12px !important;
        margin-right: 12px !important;
}

.bg_homecard2 {
        background-image: url('../../assets/images/bg_home2.png');
        background-size: cover;
        background-repeat: repeat-x;
        margin-left: 12px !important;
        margin-right: 12px !important;
}

.first_img {
        position: absolute;
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
        .first_img,h4,p {
                font-size: small !important;
        }
        .marg_sm{
                padding-top: 10px !important;
        }
}

/* .btn-res{
        margin-left: 0px;
} */
@media only screen and (max-width: 1440px) and (min-width: 1201px) {
        .margin-content {
                margin-top: 40px;
        }
}

@media only screen and (max-width: 1200px) {
        .background-img {
                background-image: url('../../assets/images/metabackgroud1.png');
                background-size: auto;
                background-repeat: repeat-x;
                background-position: top;
        }
}

@media only screen and (max-width: 768px) {
        .search-button {
                border-radius: 10px;
                background-color: #5CB85C;
                border: 1px;
                height: 40px;
                color: white;
                font-weight: 500;
                margin-left: 0px;
                width: 60%;
        }

        .search-inputs {
                border-radius: 10px !important;
                height: 45px !important;
                padding-left: 20px !important;
        }

        .margin-content {
                margin-top: 240px;
        }

        /* .btn-res{
        margin-left: -52px;
} */

        .background-img {
                background-image: url('../../assets/images/metabackgroud1.png');
                background-size: auto;
                background-repeat: repeat-x;
                background-position: top;
        }

        .heading-font {
                font-size: 30px;
                font-weight: 500;
                text-align: center
        }

        .heading-font-p {
                font-size: 18px;
                text-align: center;
                font-weight: 400;
                color: '#5B5B5B'
        }


}

/* @media only screen and (max-width: 766px){
        .btn-res{
                margin-left: 0px;
        }
} */
@media only screen and (max-width: 500px) {
        .banner-logo {
                width: 100%;
        }

        .heading-font {
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin-top: 30px;
        }

        .heading-font-p {
                font-size: 14px;
                text-align: center;
                font-weight: 400;
                color: '#5B5B5B'
        }

        .content-heading {
                font-size: 15px;
                font-weight: 500;
        }

        .content-p {
                font-size: 12px;
                color: '#434343';
        }

        /* .btn-res{
                margin-left: 0px;
        } */

}