.custom-dropdownzzz {
    border-radius: 5 !important;
    padding: 5 !important;
    margin-left: 10 !important;
    margin-bottom: 10 !important;
}

.table-responsive {
    overflow-y: auto;
    height: 90vh;

}

.table-grid {
    display: block;
}

.res-card {
    display: none;
}

@media only screen and (max-width: 769px) {
    .table-grid {
        display: none;
    }

    .res-card {
        display: flex;
        flex-direction: column;
    }
}